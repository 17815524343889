import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/index.css"

import projectsImg from "../images/projects.png"
import showreelsImg from "../images/showreelz.png"
import contactImg from "../images/contact.png"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `danny`,
        `fonseca`,
        `video`,
        `editor`,
        `director`,
        `camera`,
        `operator`,
      ]}
    />
    <div
      style={{
        display: "flex",
        width: "100%",
        marginLeft: "0",
        marginRight: "0",
      }}
      className="pageImages"
    >
      <Link
        style={{ flex: "1", maxWidth: `100%`, marginBottom: `1.45rem` }}
        className="pageImage"
        to="/projects"
      >
        <img style={{ width: "100%" }} src={projectsImg} />
      </Link>
      <Link
        style={{ flex: "1", maxWidth: `100%`, marginBottom: `1.45rem` }}
        className="pageImage"
        to="/showreel"
      >
        <img style={{ width: "100%" }} src={showreelsImg} />
      </Link>
      <Link
        style={{ flex: "1", maxWidth: `100%`, marginBottom: `1.45rem` }}
        className="pageImage"
        to="/contact"
      >
        <img style={{ width: "100%" }} src={contactImg} />
      </Link>
    </div>
  </Layout>
)

export default IndexPage
